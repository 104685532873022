.container {
  --table-subheader-height: 115px;
}

.container[data-variant='dashboard'] {
  --table-header-height: 48px;
}

.container[data-variant='compact'] {
  --table-header-height: 62px;
}

.container[data-variant='standard'] {
  --table-header-height: 75px;
}

.container[data-without-header-offset='true'] {
  --nav-header-height: 0px;
}
