/* Dropdown Animation */
@media (prefers-reduced-motion: no-preference) {
  .container {
    animation-duration: 400ms;
    animation-timing-function: cubic-bezier(0.16, 1, 0.3, 1);
    will-change: transform, opacity;
  }

  .container [data-state='open'][data-side='top'] {
    animation-name: slideDownAndFade;
  }

  .container[data-state='open'][data-side='right'] {
    animation-name: slideLeftAndFade;
  }
  .container[data-state='open'][data-side='bottom'] {
    animation-name: slideUpAndFade;
  }
  .container[data-state='open'][data-side='left'] {
    animation-name: slideRightAndFade;
  }
}

@keyframes slideUpAndFade {
  0% {
    opacity: 0;
    transform: translateY(-9px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes slideRightAndFade {
  0% {
    opacity: 0;
    transform: translateX(2px);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes slideDownAndFade {
  0% {
    opacity: 0;
    transform: translateY(-2px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes slideLeftAndFade {
  0% {
    opacity: 0;
    transform: translateX(2px);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}
