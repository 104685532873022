@font-face {
  font-family: 'Azeret';
  src: url('../../kennek/assets/fonts/Azeret-ExtraBold.ttf') format('truetype');
  font-weight: 900;
}

@font-face {
  font-family: 'Azeret';
  src: url('../../kennek/assets/fonts/Azeret-Medium.ttf') format('truetype');
  font-weight: 500;
}

@font-face {
  font-family: 'Azeret';
  src: url('../../kennek/assets/fonts/Azeret-Regular.ttf') format('truetype');
  font-weight: normal;
}

@font-face {
  font-family: 'Azeret';
  src: url('../../kennek/assets/fonts/Azeret-SemiBold.ttf') format('truetype');
  font-weight: 600;
}

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
  input[type='number']::-webkit-inner-spin-button,
  input[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}

html,
body {
  padding: 0;
  margin: 0;
  @apply font-Azaret;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
  /* Scrollbar styles firefox */
  scrollbar-color: #b3b3b3 #f1f1f1;
  scrollbar-width: thin;
}

/* Custom Classes */
.link {
  @apply text-primary-500 hover:text-primary-600 cursor-pointer;
}

.form {
  @apply flex flex-col gap-6;
}

.form-input {
  border-width: 0px;
  @apply flex flex-col gap-1;
}

.form__validated {
  @apply flex flex-col gap-2;
}

.form-input__validated {
  @apply flex flex-col gap-1 h-20;
}

.text-white-wrapper {
  color: white !important;
}

.text-white-wrapper * {
  color: white !important;
  background: transparent;
}

.loader-xs-center {
  top: calc(50% - 0.75rem);
  left: calc(50% - 0.75rem);
}

.loader-sm-center {
  top: calc(50% - 1rem);
  left: calc(50% - 1rem);
}

.loader-md-center {
  top: calc(50% - 2rem);
  left: calc(50% - 2rem);
}

/* Scrollbar styles */

::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
}

::-webkit-scrollbar-thumb {
  background: #b3b3b3;
  border-radius: 6px;
}

::-webkit-scrollbar-thumb:hover {
  background: #9c9c9c;
}
