.container {
  --table-column-header-height: 36px;
}

.container[data-row-height='md'] {
  --table-row-height: 52px;
}

.container[data-row-height='lg'] {
  --table-row-height: 78px;
}

.subrow {
  background-color: #f8fafc;
}
