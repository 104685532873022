.select-input {
  height: auto;
}

.select-input > svg {
  top: 50%;
  bottom: unset;
  transform: translateY(-50%);
}

.select-input > div {
  bottom: -1.25rem;
}
