/* Dialog Animation */
@media (prefers-reduced-motion: no-preference) {
  .overlay [data-state='open'] {
    animation: overlayShow 300ms ease-out;
  }
}

@keyframes overlayShow {
  0% {
    opacity: 0;
    transform: translate(0%, 2%) scale(0.98);
  }
  100% {
    opacity: 1;
    transform: translate(0%, 0%) scale(1);
  }
}
